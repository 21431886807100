<template>
<div class="payment">
  <LandingPageHeader/>
  <div class="tw-flex tw-justify-center md:tw-flex-row tw-items-center tw-flex-col tw-w-10/12  md:tw-w-1/4 tw-m-auto tw-pt-24 tw-px-4">
    <div class="tw-mr-6 tw-mb-4">
      <svg width="161" height="160" viewBox="0 0 161 160" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.632812" width="160" height="160" rx="80" fill="#F9F4EE"/>
        <circle cx="80.633" cy="68.7629" r="23.8948" stroke="#FF7A01" stroke-width="6" stroke-linejoin="bevel" stroke-dasharray="1.5 3"/>
        <rect x="-0.0080541" y="-0.384294" width="26.1544" height="0.543593" transform="matrix(0.692135 -0.721768 -0.721768 -0.692135 16.9819 82.8835)" stroke="#514A41" stroke-width="0.543593"/>
        <rect x="144.012" y="83.1553" width="26.1544" height="0.543593" transform="rotate(-133.799 144.012 83.1553)" stroke="#514A41" stroke-width="0.543593"/>
        <rect x="35.4221" y="63.9841" width="90.6632" height="48.4411" rx="1.53295" fill="white" stroke="#514A41" stroke-width="0.934095"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M73.8695 84.553L76.2144 94.6266H68.0266V98.4693H80.7871L78.2694 84.836L78.1023 83.8662L73.8695 84.553Z" fill="#E8E7E7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M89.2252 88.2716C89.8232 89.4828 89.8627 91.1445 89.0505 92.6186L85.8812 98.4753H90.0436L92.392 94.4814C93.8129 91.9026 93.8038 88.9055 92.6468 86.5623C91.4634 84.1655 89.0429 82.4306 85.8812 82.6819C84.1595 82.8187 82.3712 83.112 80.3116 83.5352L81.0763 87.3002C83.0579 86.893 84.6775 86.6323 86.1821 86.5127C87.6128 86.399 88.6537 87.1141 89.2252 88.2716Z" fill="#E8E7E7"/>
        <path d="M81.1736 87.2923L77.6016 88.1332L77.9562 83.8925L80.5935 83.479L81.1736 87.2923Z" fill="#E8E7E7"/>
        <path d="M26.305 86.4082L35.2724 63.9841H126.237L135.205 86.4082H26.305Z" fill="white" stroke="#514A41" stroke-width="0.934095"/>
      </svg>

    </div>
    <div>
      <h3 class="pb-4">
        We have received your order!
      </h3>
      <span>Your order would be delivered to you in 3-5 working days. Thank you for choosing us!</span>
      <div class="tw-flex tw-mt-10">
        <v-btn text  rounded  class="merch mr-3" @click="$router.push({name:'ProfilePage'})" >track my order</v-btn>
        <v-btn text color="#FF7A01" @click="$router.push({name:'Home'})">BACK TO HOME</v-btn>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import LandingPageHeader from "@/components/LandingPageHeader";
export default {
  name: "Payment",
  components: { LandingPageHeader }
};
</script>

<style scoped>
.merch{
  padding: 8px 16px;
  min-width: 128px;
  width: auto;
  height: 34px;
  background: #FF7A01;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
}
.payment h3{
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.03em;
  color: #514A41;
}
.payment span{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #514A41;

}
</style>
